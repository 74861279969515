import { useEffect, useState, useCallback, useRef } from 'react';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Empty, Menu, Row, Skeleton, Spin, Divider, Input, Space } from 'antd';
import { MdOpenInNew } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import Marquee from 'react-fast-marquee';

import { withContextRoot } from 'contexts/ContextRoot/ContextRoot';
import ProductDetailsModal from 'pages/ProductDetailsModal/ProductDetailsModal';
import ProductCard from 'components/_local/ProductCard/ProductCard';
import ProfileAvatar from 'components/_local/ProfileAvatar/ProfileAvatar';

import BannerPlaceholder from 'images/no-banner-placeholder.jpg';
import EmptyProductIcon from 'images/empty-prroduct-icon.png';

import { useGetPastLivePosts } from 'apis/post';
import { useGetFeaturedProducts, useGetLiveSellingProducts, useGetProducts } from 'apis/product';

import { useScrollToTop } from 'hooks/utils';
import { useLocationQuery } from 'hooks/router';
import { guard } from 'utils/general';
import { getHomeRoute, getProductDetailsRoute, useCurrentPath, useNav } from 'utils/routes';

import {
  BannerContainer,
  SectionTitle,
  DescSection,
  DescContentContainer,
  Desc,
  FeatureSectionContainer,
  FeatureProductsScrollableContainer,
  FeaturedProductRow,
  FeaturedProductCol,
  LiveTag,
  LiveContainer,
  LiveInframeContainer,
  LiveInframeOverlay,
  LiveIframe,
  LiveSellingContainer,
  LiveProductContainer,
  LiveSellingTitle,
  LoadMoreButtonContainer,
  ProductCardContainer,
  ProductCardRow,
  SearchKeywordText,
  SearchInputContainer,
  SearchResultContainer,
  SearchResultText,
  StoreAvatarCircle,
  StoreAvatarImage,
  StoreAvatarText,
  StoreAvatarContainer,
  StoreInfoContainer,
  StoreNameText,
  WatchLiveLink,
  LiveInfoContainer,
  LiveNowContainer,
  LiveText,
  LiveDescription,
  BlinkingRedLightSVG,
  PastLiveSectionContainer,
  PastLiveScrollableContainer,
  PastLiveRow,
  PastLiveItem,
  PastLiveInframeContainer,
  PastLiveIframe,
  PastLiveSellingContainer,
  PastLiveInframeDescOverlay,
  PastLiveInframeDesc
} from './Home.styles';

import FbLogo from 'images/fb.png';
import IgLogo from 'images/ig.png';

const DEFAULT_CATEGORY = { value: 'all', label: 'All' };
const DEFAULT_CURRENT_PAGE = 1;
const DEFAULT_PAGE_LIMIT = 12;

const productDetailsRoute = getProductDetailsRoute();

const removeWhitespaceCharacters = str => str.replace(/[\r\n\t\f\v]/g, ' ');

const FeaturedProductCards = ({ products, onViewProduct }) => {
  return (
    <FeaturedProductRow gutter={{ xs: 16, sm: 16, md: 12 }} wrap={false}>
      {products.map(product => (
        <FeaturedProductCol key={product._id}>
          <ProductCard
            name={product.label}
            keyword={product.keyword}
            coverPhotoUrl={product.coverPhoto?.original?.url || product.coverPhotoFile?.url}
            priceMin={product.minMsPrice}
            priceMax={product.maxMsPrice}
            oriPriceMin={product.minOriPrice}
            oriPriceMax={product.maxOriPrice}
            onClick={() => onViewProduct(product._id)}
            isActive
            hasStock={product.hasStock}
          />
        </FeaturedProductCol>
      ))}
    </FeaturedProductRow>
  );
};

const ProductCategoryMenu = ({ isLoading, categories, selectedMenu, onMenuSelected }) => {
  return (
    <Menu mode="horizontal" overflowedIndicator="More..." selectedKeys={[selectedMenu]} onSelect={({ key }) => onMenuSelected(key)}>
      <Menu.Item key={DEFAULT_CATEGORY.value}>{DEFAULT_CATEGORY.label}</Menu.Item>
      {!isLoading && categories.map(cat => <Menu.Item key={cat._id}>{cat.label}</Menu.Item>)}
    </Menu>
  );
};

const ProductCards = ({ products, onViewProduct }) => {
  return products.map(product => (
    <Col span={12} md={8} key={product._id}>
      <ProductCard
        name={product.label}
        keyword={product.keyword}
        coverPhotoUrl={
          (product.coverPhoto && product.coverPhoto.original && product.coverPhoto.original.url) ||
          (product.coverPhotoFile && product.coverPhotoFile.url)
        }
        priceMin={product.minMsPrice}
        priceMax={product.maxMsPrice}
        oriPriceMin={product.minOriPrice}
        oriPriceMax={product.maxOriPrice}
        onClick={() => onViewProduct(product._id)}
        isActive
        hasStock={product.hasStock}
      />
    </Col>
  ));
};

const FeaturedSection = ({ isLoading, products, onViewProduct }) => {
  const { t } = useTranslation(['pageHome']);

  return isLoading ? (
    <Skeleton active />
  ) : products.length > 0 ? (
    <FeatureSectionContainer>
      <SectionTitle>{t('pageHome:featured-title')}</SectionTitle>
      <FeatureProductsScrollableContainer>
        <FeaturedProductCards products={products} onViewProduct={onViewProduct} />
      </FeatureProductsScrollableContainer>
    </FeatureSectionContainer>
  ) : null;
};

const BannerSection = ({ photoUrl }) => {
  return (
    <Row>
      <Col span={24}>
        <BannerContainer imageUrl={photoUrl || BannerPlaceholder} />
      </Col>
    </Row>
  );
};

const getInitalsFromFullName = fallbackName => {
  const nameSeperations = fallbackName ? fallbackName.split(' ') : [];

  if (nameSeperations.length > 0) {
    const firstInitial = nameSeperations[0] ? nameSeperations[0].charAt(0).toUpperCase() : '';
    const secondInitial = nameSeperations[1] ? nameSeperations[1].charAt(0).toUpperCase() : '';
    return `${firstInitial}${secondInitial}`.toLowerCase();
  }

  return 'U';
};

const StoreInfoSection = ({ storeInfo, isLive, livePost }) => {
  const { name, logoUrl, socialMediaFb, socialMediaIg } = storeInfo;

  return (
    <StoreInfoContainer>
      <StoreAvatarContainer>
        <StoreAvatarCircle isLive={isLive} onClick={() => (isLive ? window.open(livePost.liveVideoUrl, '_blank') : {})}>
          {logoUrl ? <StoreAvatarImage src={logoUrl} preview={false} /> : <StoreAvatarText>{getInitalsFromFullName(name)}</StoreAvatarText>}
        </StoreAvatarCircle>
        {isLive && <LiveTag>LIVE</LiveTag>}
      </StoreAvatarContainer>
      <Space style={{ marginTop: '20px' }}>
        <StoreNameText>{name}</StoreNameText>
        {socialMediaFb && (
          <ProfileAvatar
            src={FbLogo}
            size={24}
            isSquare
            isHideShadow
            onClick={() => window.open(`https://www.facebook.com/${socialMediaFb}`, '_blank')}
            style={{ marginLeft: '8px' }}
          />
        )}
        {socialMediaIg && (
          <ProfileAvatar
            src={IgLogo}
            size={24}
            isSquare
            isHideShadow
            onClick={() => window.open(`https://www.instagram.com/${socialMediaIg}`, '_blank')}
            style={{ marginLeft: '8px' }}
          />
        )}
      </Space>
      <Divider />
    </StoreInfoContainer>
  );
};

const LiveSection = ({ livePost, isLoading, products, onViewProduct }) => {
  const { t } = useTranslation(['pageHome']);
  const title = livePost.title || '';
  const description = livePost.description || '';
  const marqueeText = ((title || description) && removeWhitespaceCharacters(`${title} ${description}`)) || t('pageHome:live-description');

  return (
    <LiveContainer gutter={0}>
      <Col span={24}>
        <LiveInfoContainer>
          <LiveNowContainer isLive={livePost.isLive}>
            {livePost.isLive ? (
              <BlinkingRedLightSVG width={10} height={10}>
                <circle cx="5" cy="5" r="5" fill="red" />
              </BlinkingRedLightSVG>
            ) : null}
            <LiveText>{livePost.isLive ? t('pageHome:live-now') : t('pageHome:live-ended')}</LiveText>
          </LiveNowContainer>
          <Marquee delay={2}>
            <LiveDescription>{marqueeText}</LiveDescription>
          </Marquee>
        </LiveInfoContainer>
      </Col>
      <Col xs={24} md={10}>
        <LiveInframeContainer>
          <a href={livePost.liveVideoUrl} target="_blank" rel="noreferrer">
            <LiveInframeOverlay>
              <span>{t('pageHome:watch-live-on-fb')}</span>
              <MdOpenInNew size="24px" style={{ marginLeft: '4px' }} />
            </LiveInframeOverlay>
          </a>
          <LiveIframe src={livePost.liveStreamIframeUrl} />
        </LiveInframeContainer>
      </Col>
      <Col xs={24} md={14}>
        <LiveSellingContainer>
          <LiveSellingTitle>{t('pageHome:selling-now-title')}</LiveSellingTitle>
          <LiveProductContainer>
            {products.length > 0 ? (
              <Space direction="vertical" style={{ width: '100%' }}>
                {products.map(product => (
                  <ProductCard
                    key={product._id}
                    name={product.label}
                    keyword={product.keyword}
                    coverPhotoUrl={product.coverPhoto?.original?.url || product.coverPhotoFile?.url}
                    priceMin={product.minMsPrice}
                    priceMax={product.maxMsPrice}
                    oriPriceMin={product.minOriPrice}
                    oriPriceMax={product.maxOriPrice}
                    onClick={() => onViewProduct(product._id, livePost._id)}
                    isActive={product.isActive}
                    hasStock={product.hasStock}
                    quantityLeft={product.inventory}
                    isLiveSelling
                  />
                ))}
              </Space>
            ) : isLoading ? (
              <Skeleton active />
            ) : (
              <WatchLiveLink href={livePost.liveVideoUrl} target="_blank" rel="noreferrer">
                <span>{t('pageHome:watch-live-on-fb')}</span>
                <MdOpenInNew size="24px" style={{ marginLeft: '4px' }} />
              </WatchLiveLink>
            )}
          </LiveProductContainer>
        </LiveSellingContainer>
      </Col>
    </LiveContainer>
  );
};

const PastLiveSection = ({ isLoading, socialMediaFbPageId, pastLivePosts, onViewProduct }) => {
  const { t } = useTranslation(['pageHome']);
  const fbVideosLink = socialMediaFbPageId ? `https://www.facebook.com/${socialMediaFbPageId}/videos` : undefined;

  return isLoading ? (
    <Skeleton active />
  ) : pastLivePosts.length > 0 ? (
    <PastLiveSectionContainer>
      <SectionTitle>{t('pageHome:pastLive-title')}</SectionTitle>
      <PastLiveScrollableContainer>
        <PastLiveRow gutter={{ xs: 16, sm: 16, md: 12 }}>
          {pastLivePosts.map(pastLivePost => (
            <Col gutter={0}>
              <PastLiveItem gutter={0}>
                <Col span={24}>
                  <PastLiveInframeContainer>
                    <a href={pastLivePost.liveVideoUrl} target="_blank" rel="noreferrer">
                      <PastLiveInframeDescOverlay>
                        <PastLiveInframeDesc ellipsis={{ rows: 3 }}>
                          {pastLivePost.title || pastLivePost.description || t('pageHome:live-description')}
                        </PastLiveInframeDesc>
                      </PastLiveInframeDescOverlay>
                      <LiveInframeOverlay>
                        <span>{t('pageHome:watch-live-on-fb')}</span>
                        <MdOpenInNew size="24px" style={{ marginLeft: '4px' }} />
                      </LiveInframeOverlay>
                    </a>
                    <PastLiveIframe src={pastLivePost.liveStreamIframeUrl} />
                  </PastLiveInframeContainer>
                </Col>
                <Col span={24}>
                  <PastLiveSellingContainer>
                    <LiveSellingTitle>{t('pageHome:selling-now-title')}</LiveSellingTitle>
                    <LiveProductContainer>
                      {pastLivePost.sellingProducts.length > 0 ? (
                        <Space direction="vertical" style={{ width: '100%' }}>
                          {pastLivePost.sellingProducts.map(product => (
                            <ProductCard
                              key={product._id}
                              name={product.label}
                              keyword={product.keyword}
                              coverPhotoUrl={product.coverPhoto?.original?.url || product.coverPhotoFile?.url}
                              priceMin={product.minMsPrice}
                              priceMax={product.maxMsPrice}
                              oriPriceMin={product.minOriPrice}
                              oriPriceMax={product.maxOriPrice}
                              onClick={() => onViewProduct(product._id, pastLivePost._id)}
                              isActive={product.isActive}
                              hasStock={product.hasStock}
                              quantityLeft={product.inventory}
                              isLiveSelling
                            />
                          ))}
                        </Space>
                      ) : (
                        <WatchLiveLink href={pastLivePost.liveVideoUrl} target="_blank" rel="noreferrer">
                          <span>{t('pageHome:watch-live-on-fb')}</span>
                          <MdOpenInNew size="24px" style={{ marginLeft: '4px' }} />
                        </WatchLiveLink>
                      )}
                    </LiveProductContainer>
                  </PastLiveSellingContainer>
                </Col>
              </PastLiveItem>
            </Col>
          ))}
          {!!fbVideosLink && (
            <Col gutter={0}>
              <PastLiveItem gutter={0}>
                <Col span={24} style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <WatchLiveLink href={fbVideosLink} target="_blank" rel="noreferrer">
                    <span>{t('pageHome:view-all-videos-in-fb')}</span>
                    <MdOpenInNew size="24px" style={{ marginLeft: '4px' }} />
                  </WatchLiveLink>
                </Col>
              </PastLiveItem>
            </Col>
          )}
        </PastLiveRow>
      </PastLiveScrollableContainer>
    </PastLiveSectionContainer>
  ) : null;
};

const StoreDescSection = ({ briefInfo }) => {
  return (
    <DescSection>
      <DescContentContainer>
        <Desc>{briefInfo}</Desc>
      </DescContentContainer>
    </DescSection>
  );
};

const ProductsSection = ({
  isLoading,
  isLoadingShowMore,
  shouldShowLoadMore,
  products = [],
  totalCount,
  onLoadMore,
  onViewProduct,
  onClickNoProductButton,
  selectedMenu,
  searchKeyword,
  onProductSearch
}) => {
  const { t } = useTranslation(['pageHome']);

  return (
    <ProductCardContainer>
      <SearchInputContainer>
        <Input.Search placeholder={t('pageHome:search-product-placeholder')} enterButton={<SearchOutlined />} onSearch={onProductSearch} />
      </SearchInputContainer>
      {searchKeyword && (
        <>
          <Divider />
          <SearchResultContainer>
            <SearchResultText>
              {t('pageHome:search-product-results')} “<SearchKeywordText>{searchKeyword}</SearchKeywordText>”
            </SearchResultText>
            {totalCount > 0 && <p>{t('pageHome:search-product-items-found', { itemCount: totalCount })}</p>}
          </SearchResultContainer>
        </>
      )}

      {products.length > 0 ? (
        <>
          <Spin spinning={isLoading && !isLoadingShowMore}>
            <ProductCardRow
              gutter={[
                { xs: 16, sm: 16, md: 12 },
                { xs: 16, sm: 16, md: 12 }
              ]}
            >
              <ProductCards products={products} onViewProduct={onViewProduct} />
            </ProductCardRow>
          </Spin>
          {shouldShowLoadMore && (
            <LoadMoreButtonContainer>
              <Button type="primary" ghost onClick={onLoadMore} loading={isLoadingShowMore} icon={<DownOutlined />}>
                {t('pageHome:load-more-button')}
              </Button>
            </LoadMoreButtonContainer>
          )}
        </>
      ) : searchKeyword ? (
        <div style={{ textAlign: 'center', padding: '24px 0' }}>
          <h1 style={{ marginBottom: 0 }}>{t('pageHome:search-product-no-found-title')}</h1>
          <p>{t('pageHome:search-product-no-found-desc')}</p>
          <SearchOutlined style={{ fontSize: '52px', marginTop: '24px' }} />
        </div>
      ) : (
        <Empty
          image={EmptyProductIcon}
          imageStyle={{
            height: 60
          }}
          description={
            <>
              <p style={{ marginBottom: 0 }}>{t('pageHome:products-empty-product-desc')}</p>
              <Button type="link" style={{ padding: 0 }} onClick={onClickNoProductButton}>
                {t('pageHome:products-empty-product-cta')}
              </Button>
            </>
          }
        />
      )}
    </ProductCardContainer>
  );
};

const Home = ({ propsContextRoot }) => {
  const { storeInfo, isLoading: isLoadingContextRoot } = propsContextRoot;
  const { categories, descriptions, live: livePost, isLive, isAllowMsLiveVideo } = storeInfo;

  const currentPath = useCurrentPath();
  const nav = useNav();
  const query = useLocationQuery();
  const queryCategory = guard(() => query.category);
  const [productQuery, setProductQuery] = useState({
    currentPage: DEFAULT_CURRENT_PAGE,
    limit: DEFAULT_PAGE_LIMIT
  });
  const [products, setProducts] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState(DEFAULT_CATEGORY.value);
  const [isLoadMoreClicked, setIsLoadMoreClicked] = useState(false);
  const [isMenuClicked, setIsMenuClicked] = useState(false);
  const [isNewProductDataConsolidated, setIsNewProductDataConsolidated] = useState(false);

  const [searchKeyword, setSearchKeyword] = useState('');

  const {
    data: { products: paginatedProducts, totalCount },
    isLoading: isProductsLoading,
    refetch: refetchProducts
  } = useGetProducts({ ...productQuery, filter: { ...productQuery.filter, label: searchKeyword } });

  const { isLoading: isPastLivePostLoading, data: pastLivePosts } = useGetPastLivePosts(isAllowMsLiveVideo);
  const { isLoading: isFeaturedProductLoading, data: featuredProducts } = useGetFeaturedProducts();
  const { isLoading: isSellingProductLoading, data: liveSellingProducts } = useGetLiveSellingProducts(
    livePost ? livePost._id : null,
    {},
    isLive ? 10000 : false
  );

  const categoryMenuRef = useRef();

  useScrollToTop();

  useEffect(() => {
    if (!isProductsLoading) {
      if (!isLoadMoreClicked || isMenuClicked) {
        setProducts(paginatedProducts);
        setIsMenuClicked(false);
      } else {
        if (!isNewProductDataConsolidated) {
          setProducts([...products, ...paginatedProducts]);
          setIsNewProductDataConsolidated(true);
        }
      }
    }
  }, [isLoadMoreClicked, isMenuClicked, isNewProductDataConsolidated, isProductsLoading, paginatedProducts, products]);

  const handleOnSelectMenu = useCallback(
    key => {
      const isDefaultCategory = key === DEFAULT_CATEGORY.value;

      setSearchKeyword('');
      setSelectedMenu(key);
      setIsMenuClicked(true);
      setIsLoadMoreClicked(false);
      setProductQuery({ ...productQuery, currentPage: DEFAULT_CURRENT_PAGE, filter: !isDefaultCategory ? { category: key } : undefined });
      refetchProducts();
      nav(getHomeRoute(!isDefaultCategory ? key : undefined).path);
    },
    [nav, productQuery, refetchProducts]
  );

  useEffect(() => {
    if (!isFeaturedProductLoading && queryCategory && selectedMenu !== queryCategory && categoryMenuRef.current) {
      handleOnSelectMenu(queryCategory);
      categoryMenuRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isFeaturedProductLoading, queryCategory, selectedMenu, handleOnSelectMenu]);

  const handleOnClickProductCard = (productId, postId) => nav(getProductDetailsRoute(productId, postId).path);
  const handleOnClickLoadMore = () => {
    setIsLoadMoreClicked(true);
    setProductQuery({ ...productQuery, currentPage: productQuery.currentPage + 1 });
    setIsNewProductDataConsolidated(false);
  };

  return (
    <div style={{ scrollBehavior: 'smooth' }}>
      {currentPath === productDetailsRoute.path && <ProductDetailsModal />}
      <BannerSection photoUrl={storeInfo.bannerUrl} />
      <StoreInfoSection storeInfo={storeInfo} isLive={isLive} livePost={livePost} />
      {livePost && (
        <LiveSection
          isLoading={isSellingProductLoading}
          livePost={livePost}
          products={liveSellingProducts}
          onViewProduct={handleOnClickProductCard}
        />
      )}
      {isAllowMsLiveVideo && (
        <PastLiveSection
          isLoading={isPastLivePostLoading}
          socialMediaFbPageId={storeInfo.socialMediaFbPageId}
          pastLivePosts={pastLivePosts}
          onViewProduct={handleOnClickProductCard}
        />
      )}
      <FeaturedSection isLoading={isFeaturedProductLoading} products={featuredProducts} onViewProduct={handleOnClickProductCard} />
      {descriptions && <StoreDescSection briefInfo={descriptions} />}
      <div ref={categoryMenuRef}>
        <ProductCategoryMenu
          isLoading={isLoadingContextRoot}
          categories={categories}
          selectedMenu={selectedMenu}
          onMenuSelected={handleOnSelectMenu}
        />
      </div>

      {/* Kong: Revisit logic here*/}
      {/* Loading = when load first page product, Show more btn loading = when load new page */}
      {/* Should show load more = Show load more button while loading, or show when products is not showing all */}
      <ProductsSection
        key={selectedMenu}
        isLoading={isProductsLoading}
        isLoadingShowMore={isLoadMoreClicked && isProductsLoading}
        shouldShowLoadMore={
          (isLoadMoreClicked && isProductsLoading) || (!isMenuClicked && !!totalCount && totalCount > DEFAULT_PAGE_LIMIT * productQuery.currentPage)
        }
        totalCount={totalCount}
        products={products}
        onLoadMore={handleOnClickLoadMore}
        onViewProduct={handleOnClickProductCard}
        onClickNoProductButton={() => {
          handleOnSelectMenu(DEFAULT_CATEGORY.value);
        }}
        selectedMenu={selectedMenu}
        searchKeyword={searchKeyword}
        onProductSearch={keyword => {
          setSearchKeyword(keyword);
          setIsLoadMoreClicked(false);
          setProductQuery({ ...productQuery, currentPage: 1 });
        }}
      />
    </div>
  );
};

export default withContextRoot(Home);
